import { createApp, watch, ref } from 'vue';
import { createPinia } from 'pinia';
import 'element-plus/es/components/loading/style/css';
import { ElLoading } from 'element-plus';
import App from './App.vue';
import './assets/main.css';
import '@/assets/iconfont/iconfont.css';
// import 'element-plus/es/components/message/style/css';

import installIcon from '@/plugins/installIcon'; // icon 注册
import { router } from '@/plugins/installRouter'; // 路由注册
import eventBus from 'vue3-eventbus';


const app = createApp(App);
// app.config.globalProperties.$showLoading = ref(false);
// app.config.globalProperties.$ElLoading = ElLoading.service({
//     text: '核心加载中...'
// });

import * as cookies from "/src/utils/cookies";
app.config.globalProperties.$cookies = cookies;


// router.beforeEach((to, from, next) => {
// 	const isAuthenticated = cookies.getToken();
// 	if(isAuthenticated){
// 		next();
// 	}else{
// 		eventBus.emit('showLoginDialog', true);
// 	}
// })

const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(installIcon);

app.mount('#app');
