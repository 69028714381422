import Cookies from "js-cookie";
 
//变量
const tokenKey = "token";
const usernameKey = "username";
const useridKey = "userid";
const tokenTypeKey = 'tokenType';
const authKey = "isAuthenticated";
const ttsTokenKey = 'tts_token';

//获取urlKey
export function getTTSToken() {
  return Cookies.get(ttsTokenKey);
}
 
//写入urlKey
export function setTTSToken(data) {
  return Cookies.set(ttsTokenKey, data, {expires: 30});
}
 
//删除urlKey
export function removeTTSToken() {
  return Cookies.remove(ttsTokenKey);
}

//获取urlKey
export function getToken() {
  return Cookies.get(tokenKey);
}
 
//写入urlKey
export function setToken(data) {
  return Cookies.set(tokenKey, data, {expires: 30});
}
 
//删除urlKey
export function removeToken() {
  return Cookies.remove(tokenKey);
}

//获取urlKey
export function getUsername() {
  return Cookies.get(usernameKey);
}
 
//写入urlKey
export function setUsername(data) {
  return Cookies.set(usernameKey, data, {expires: 30});
}
 
//删除urlKey
export function removeUsername() {
  return Cookies.remove(usernameKey);
}

//获取urlKey
export function getUserid() {
  return Cookies.get(useridKey);
}
 
//写入urlKey
export function setUserid(data) {
  return Cookies.set(useridKey, data, {expires: 30});
}
 
//删除urlKey
export function removeUserid() {
  return Cookies.remove(useridKey);
}
